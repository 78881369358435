import { Container } from "@mantine/core";
import { Link } from "wouter-preact";

const component = () => {
  return (
    <Container pt={20} size="md">
      La compagnie{" "}
      <a href="http://pharealucioles.org" target="_blank">
        Le Phare à Lucioles
      </a>{" "}
      a initié en 2020 un projet de commande à des artistes du sonore pour composer des petites pièces en relation aux commerces du village
      de Sault. Le principe est simple : un·e artiste passe une journée dans un commerce du village. La rencontre, l’atmosphère du lieu, la
      vie du commerce ou encore les enregistrements <i>in situ</i> donnent la matière principale permettant à l’artiste de composer, écrire
      et enregistrer une petite pastille sonore de 2 à 3 minutes. La pastille sonore est ensuite enregistrée sur une plateforme d’écoute et
      accessible via un QR-code affiché sur chaque façade des commerces participants.
      <br />
      <br />
      <Link
        style={{
          textAlign: "center",
        }}
        href="/map/sault"
      >
        <b>
          Zoomez, déplacez-vous sur la carte et cliquez sur les icônes des commerces pour écouter leurs pastilles sonores !<br />
        </b>
      </Link>
      <br />
      Avec la participation de : <br />
      <ul>
        <li>
          <a href="https://ericbrochard.net/" target="_blank">
            Eric Brochard
          </a>{" "}
          &{" "}
          <a href="https://www.facebook.com/lebonheurestdanslechai/" target="_blank">
            Le Bonheur est dans le chai
          </a>{" "}
        </li>
        <li>
          <a href="https://pascaleberthelot.com/" target="_blank">
            Pascale Berthelot
          </a>{" "}
          &{" "}
          <a href="https://www.nougat-boyer.fr/" target="_blank">
            Nougatier André Boyer
          </a>{" "}
        </li>
        <li>
          <a href="http://nainoprod.com/bio-pascal-charrier/" target="_blank">
            Pascal Charrier
          </a>{" "}
          & Les Années Cassandre, Antiquités{" "}
        </li>
        <li>
          <a href="https://gmem.org/" target="_blank">
            Christian Sébille
          </a>{" "}
          &{" "}
          <a href="https://www.albioncycles.com/" target="_blank">
            Albion Cycles
          </a>{" "}
        </li>
        <li>
          <a href="https://masoeurmusique.wixsite.com/masoeur/" target="_blank">
            Gabrielle Gonin
          </a>{" "}
          &{" "}
          <a href="https://www.facebook.com/biovaleur" target="_blank">
            Biovaleur
          </a>{" "}
        </li>
        <li>
          <a href="http://www.fabricefavriou.com/" target="_blank">
            Fabrice Favriou
          </a>{" "}
          &{" "}
          <a href="https://www.facebook.com/coiffure.sault" target="_blank">
            Au Fil de l&apos;Hair
          </a>{" "}
        </li>
        <li>
          Guigou Chenevier &{" "}
          <a href="https://www.facebook.com/profile.php?id=100054361578787" target="_blank">
            Aux saveurs du Ventoux
          </a>{" "}
          (Boulangerie, Pâtisserie){" "}
        </li>
        <li>
          <a href="https://www.facebook.com/alice.pierot.84" target="_blank">
            Alice Piérot
          </a>{" "}
          &{" "}
          <a href="https://www.materiaux-simc.fr/decouvrez-materiaux-simc-sault/" target="_blank">
            Simc Matériaux
          </a>{" "}
        </li>
        <li>
          <a href="https://ottilieb.com/" target="_blank">
            Ottilie [B]
          </a>{" "}
          &{" "}
          <a href="http://lamarguerite.free.fr/" target="_blank">
            La Marguerite
          </a>{" "}
          (Fleuriste){" "}
        </li>
        <li>
          <a href="http://marinerodallec.fr/" target="_blank">
            Marine Rodallec
          </a>{" "}
          &{" "}
          <a href="https://www.maisondesproducteurs-sault84.com/" target="_blank">
            La Maison des Producteurs
          </a>{" "}
        </li>
        <li>
          <a href="https://amandagardone.wixsite.com/contrebasse" target="_blank">
            Amanda Gardone
          </a>{" "}
          & Office notarial{" "}
        </li>
        <li>
          <a href="https://pellegriniemmanuelle.wordpress.com" target="_blank">
            Emmanuelle Pellegrini
          </a>{" "}
          & Pizza Bruno{" "}
        </li>
        <li>
          <a href="https://www.loisebulot.com/" target="_blank">
            Loïse Bulot
          </a>{" "}
          & Boulangerie Banette{" "}
        </li>
        <li>Maxime Echardour & Family & Cie</li>
      </ul>
      <i>
        Le Phare à Lucioles reçoit le soutien du Ministère de la Culture / Drac-Paca, de la Région Sud, du Département de Vaucluse, de la
        Communauté de Communes Ventoux Sud et de la Commune de Sault. Le Phare est soutenu et accompagné par la Sacem, la Spédidam et
        l’Adami. La compagnie est membre du réseau Futurs Composés.
      </i>
    </Container>
  );
};

export default component;
