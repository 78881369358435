import { ActionIcon, AppShell, Burger, Button, Center, Group, MantineProvider, NavLink, Title, createTheme } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconMapPin, IconQrcode } from "@tabler/icons-react";
import { Suspense, lazy } from "preact/compat";
import { useEffect, useState } from "preact/hooks";
import { Link, Route, Switch } from "wouter-preact";
import Informations from "./infos";
import LoadingComponent from "./loading";
import { Parcours, pb } from "./types";

const theme = createTheme({
  fontFamily: "Rubik, sans-serif",
  primaryColor: "blue",
  colors: {
    "ocean-blue": ["#7AD1DD", "#5FCCDB", "#44CADC", "#2AC9DE", "#1AC2D9", "#11B7CD", "#09ADC3", "#0E99AC", "#128797", "#147885"],
    "bright-pink": ["#F0BBDD", "#ED9BCF", "#EC7CC3", "#ED5DB8", "#F13EAF", "#F71FA7", "#FF00A1", "#E00890", "#C50E82", "#AD1374"],
  },
});

const VilleMap = lazy(() => import("./map"));

const VilleComponent = () => {
  const [opened, { toggle }] = useDisclosure();
  const [parcours, setParcours] = useState<Parcours[]>([]);

  useEffect(() => {
    (async () => {
      setParcours(
        await pb.collection("parcours").getFullList({
          expand: "ville",
        }),
      );
    })();
  }, []);

  return (
    <MantineProvider theme={theme} defaultColorScheme="dark">
      <AppShell
        padding={0}
        zIndex={100000}
        header={{ height: 60 }}
        navbar={{ width: 300, breakpoint: "sm", collapsed: { desktop: true, mobile: !opened } }}
        styles={{
          main: {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <AppShell.Header zIndex={8000}>
          <Group h="100%" px="md">
            <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
            <Link href="/" asChild>
              <Group component="a" justify="space-between" style={{ flex: 1, textDecoration: "none", color: "inherit" }}>
                <ActionIcon variant="filled">
                  <IconQrcode size="2.0rem" />
                </ActionIcon>
                <Title order={2} style={{ flex: 1 }}>
                  Sonore
                </Title>
                <Group ml="xl" gap={0} visibleFrom="sm">
                  {parcours.map((track, index) => (
                    <Link key={index} href={`/map/${track.anchor}`} asChild>
                      <Button key={track.id} ml={10} color="gray" leftSection={<IconMapPin size="1.5rem" stroke={1.5} />}>
                        {track.nom}
                      </Button>
                    </Link>
                  ))}
                </Group>
              </Group>
            </Link>
          </Group>
        </AppShell.Header>
        <AppShell.Navbar py="md" px={4}>
          {parcours.map((track, index) => (
            <Link key={index} onClick={toggle} href={`/map/${track.anchor}`} asChild>
              <NavLink
                key={track.id}
                label={track.nom}
                description={track.expand?.ville?.nom}
                color="gray"
                leftSection={<IconMapPin size="1.5rem" stroke={1.5} />}
              />
            </Link>
          ))}
        </AppShell.Navbar>
        <AppShell.Main>
          <Suspense fallback={<LoadingComponent />}>
            <Switch>
              <Route path="/" component={Informations} />
              <Route path="/map/:id?/:point?" component={VilleMap}></Route>
              <Center default maw={400} h={100} mx="auto">
                Page non trouvée !
              </Center>
            </Switch>
          </Suspense>
        </AppShell.Main>
      </AppShell>
    </MantineProvider>
  );
};

export default VilleComponent;
