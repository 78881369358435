import { Box, LoadingOverlay } from "@mantine/core";

const LoadingComponent = () => {
  return (
    <Box>
      <LoadingOverlay visible={true} zIndex={1000} overlayProps={{ blur: 2 }} />
    </Box>
  );
};

export default LoadingComponent;
